import React, {lazy} from 'react';
import {
    componentsMenu,
    demoPages,
    menuCalendario,
    menuCalendarioAdmin,
    menuPacientes,
    menuPacientesAdmin,
    menuPersonalMedico,
} from '../menu';
import Login from '../pages/auth/login';
import {_user} from "../helpers";

const PERFIL = {
    MI_PERFIL: lazy(() => import('../pages/perfil/perfil/perfil')),
    EDITAR_PERFIL: lazy(() => import('../pages/perfil/editar-perfil/editarPerfil')),
};

const MENU_PACIENTES = {
    NUEVO_PACIENTE: lazy(() => import('../pages/menu-pacientes/nuevo-paciente/nuevoPaciente')),
    EDITAR_PACIENTE: lazy(() => import('../pages/menu-pacientes/editar-paciente/editarPaciente')),
    DETALLE_PACIENTE: lazy(() =>
        import('../pages/menu-pacientes/detalle-paciente/detallePaciente'),
    ),
    EXPEDIENTES_CLINICOS: lazy(() =>
        import('../pages/menu-pacientes/expedientes-clinicos/expedientesClinicos'),
    ),
};

const MENU_CALENDARIO = {
    NUEVA_CITA: lazy(() => import('../pages/menu-calendario/nueva-cita/nuevaCita')),
    CITAS_AGENDADAS: lazy(() =>
        import('../pages/menu-calendario/citas-agendadas/citasAgendadasTV'),
    ),
    DETALLE_CITA: lazy(() => import('../pages/menu-calendario/detalle-cita/detalleCita')),
    CITAS_AGENDADAS_DEMO: lazy(() =>
        import('../pages/menu-calendario/citas-agendadas/citasAgendadasTVDemo'),
    ),
};

const MENU_PERSONAL_MEDICO = {
    NUEVO_MEDICO: lazy(() => import('../pages/menu-personal-medico/nuevo-medico/nuevoMedico')),
    CUENTAS_DE_MEDICOS: lazy(() =>
        import('../pages/menu-personal-medico/cuentas-medicos/cuentasMedicos'),
    ),
    DETALLE_MEDICO: lazy(() =>
        import('../pages/menu-personal-medico/detalle-medico/detalleMedico'),
    ),
    EDITAR_MEDICO: lazy(() => import('../pages/menu-personal-medico/editar-medico/editarMedico')),
    ESPECIALIDADES: lazy(() =>
        import('../pages/menu-personal-medico/especialidades/especialidades'),
    ),
};

const ANALISIS_E_INFORMES = {
    ESTADISTICAS: lazy(() => import('../pages/menu-analisis-e-informes/estadisticas/estadisticas')),
};

const CONFIGURACION = {
    TEMAS: lazy(() => import('../pages/menu-configuracion/temas/temas')),
    NUEVO_TEMA: lazy(() => import('../pages/menu-configuracion/nuevo-tema/nuevoTema')),
    EDITAR_TEMA: lazy(() => import('../pages/menu-configuracion/editar-tema/editarTema')),
};

const CENTROS_MEDICOS = {
    HOSPITALES: lazy(() => import('../pages/menu-centros-medicos/hospitales/hospitales')),
    NUEVO_HOSPITAL: lazy(() =>
        import('../pages/menu-centros-medicos/nuevo-hospital/nuevoHospital'),
    ),
    EDITAR_HOSPITAL: lazy(() =>
        import('../pages/menu-centros-medicos/editar-hospital/editarHospital'),
    ),
    DETALLE_HOSPITAL: lazy(() =>
        import('../pages/menu-centros-medicos/detalle-hospital/detalleHospital'),
    ),

    CONTACTOS: lazy(() => import('../pages/menu-centros-medicos/contactos/contactos')),
    // NUEVO_CONTACTO: lazy(()=> import('../pages/menu-centros-medicos/nuevo-contacto/nuevoContacto')),
    EDITAR_CONTACTO: lazy(() =>
        import('../pages/menu-centros-medicos/editar-contacto/editarContacto'),
    ),
    DETALLE_CONTACTO: lazy(() =>
        import('../pages/menu-centros-medicos/detalle-contacto/detalleContacto'),
    ),
};

const AUTH = {
    PAGE_404: lazy(() => import('../pages/presentation/auth/Page404')),
};

let inicial = {
    path: '/',
    element: <MENU_PACIENTES.EXPEDIENTES_CLINICOS/>,
    exact: true,
}
const pathname = window.location.pathname;
const section = pathname.split("/")[1];

if (section != 'login') {
    // debugger
    if (_user('rol') === 'Super Administrador') {
        inicial = {
            path: '/',
            element: <ANALISIS_E_INFORMES.ESTADISTICAS/>,
            exact: true,
        }
    }

    if (_user('rol') === 'Administrador') {
        inicial = {
            path: '/',
            element: <ANALISIS_E_INFORMES.ESTADISTICAS/>,
            exact: true,
        }
    }
    if (_user('rol') === 'Contacto Administrativo' || _user('rol') === 'Contacto Técnico') {
        inicial = {
            path: '/',
            element: <ANALISIS_E_INFORMES.ESTADISTICAS/>,
            exact: true,
        }
    }

    if (_user('rol') === 'Médico Cirujano' || _user('rol') === 'Médico Especialista') {
        inicial = {
            path: '/',
            element: <MENU_CALENDARIO.CITAS_AGENDADAS/>,
            exact: true,
        }
    }
}


const presentation = [
    /**
     * Menu pacientes
     */

    //OCULTAR MENU NUEVO PACIENTE
    {
        path: menuPacientesAdmin.nuevoPaciente.path,
        element: <MENU_PACIENTES.NUEVO_PACIENTE/>,
        exact: true,
    },
    {
        path: 'control-de-pacientes/editar-paciente',
        element: <MENU_PACIENTES.EDITAR_PACIENTE/>,
        exact: true,
    },

    // INICIAL

    inicial,

    {
        path: menuPacientes.expedientesClinicos.path,
        element: <MENU_PACIENTES.EXPEDIENTES_CLINICOS/>,
        exact: true,
    },
    {
        path: 'control-de-pacientes/detalle-paciente',
        element: <MENU_PACIENTES.DETALLE_PACIENTE/>,
        exact: true,
    },
    /**
     * Menu calendario
     */
    {
        path: '/calendario/nueva-cita',
        element: <MENU_CALENDARIO.NUEVA_CITA/>,
        exact: true,
    },

    {
        path: menuCalendario.citasAgendadas.path,
        element: <MENU_CALENDARIO.CITAS_AGENDADAS/>,
        exact: true,
    },

    {
        path: 'calendario/citas-agendadas-demo',
        element: <MENU_CALENDARIO.CITAS_AGENDADAS_DEMO/>,
        exact: true,
    },

    {
        path: 'calendario/detalle-cita',
        element: <MENU_CALENDARIO.DETALLE_CITA/>,
        exact: true,
    },

    /**
     * Menu personal medico
     */
    {
        path: menuPersonalMedico.nuevo_medico.path,
        element: <MENU_PERSONAL_MEDICO.NUEVO_MEDICO/>,
        exact: true,
    },

    {
        path: menuPersonalMedico.cuentas_de_medico.path,
        element: <MENU_PERSONAL_MEDICO.CUENTAS_DE_MEDICOS/>,
        exact: true,
    },
    {
        path: 'personal-medico/detalle-medico',
        element: <MENU_PERSONAL_MEDICO.DETALLE_MEDICO/>,
        exact: true,
    },
    {
        path: 'personal-medico/editar-medico',
        element: <MENU_PERSONAL_MEDICO.EDITAR_MEDICO/>,
        exact: true,
    },
    {
        path: menuPersonalMedico.especialidades.path,
        element: <MENU_PERSONAL_MEDICO.ESPECIALIDADES/>,
        exact: true,
    },

    /**
     * Análisis e informes
     */
    {
        path: '/analisis-e-informes/estadisticas',
        element: <ANALISIS_E_INFORMES.ESTADISTICAS/>,
        exact: true,
    },

    /**
     * Configuración
     */
    {
        path: '/configuracion/temas',
        element: <CONFIGURACION.TEMAS/>,
        exact: true,
    },
    {
        path: '/configuracion/nuevo-tema',
        element: <CONFIGURACION.NUEVO_TEMA/>,
        exact: true,
    },
    {
        path: '/configuracion/editar-tema',
        element: <CONFIGURACION.EDITAR_TEMA/>,
        exact: true,
    },

    /**
     * Centros médicos
     */
    {
        path: '/centros-medicos/hospitales',
        element: <CENTROS_MEDICOS.HOSPITALES/>,
        exact: true,
    },
    {
        path: '/centros-medicos/nuevo-hospital',
        element: <CENTROS_MEDICOS.NUEVO_HOSPITAL/>,
        exact: true,
    },
    {
        path: '/centros-medicos/editar-hospital',
        element: <CENTROS_MEDICOS.EDITAR_HOSPITAL/>,
        exact: true,
    },
    {
        path: '/centros-medicos/detalle-hospital',
        element: <CENTROS_MEDICOS.DETALLE_HOSPITAL/>,
        exact: true,
    },
    {
        path: '/centros-medicos/contactos',
        element: <CENTROS_MEDICOS.CONTACTOS/>,
        exact: true,
    },
    // {
    // 	path: '/centros-medicos/nuevo-contacto',
    // 	element: <CENTROS_MEDICOS.NUEVO_CONTACTO />,
    // 	exact: true,
    // },
    {
        path: '/centros-medicos/editar-contacto',
        element: <CENTROS_MEDICOS.EDITAR_CONTACTO/>,
        exact: true,
    },
    {
        path: '/centros-medicos/detalle-contacto',
        element: <CENTROS_MEDICOS.DETALLE_CONTACTO/>,
        exact: true,
    },

    /**
     * Perfil
     */
    {
        path: '/mi-perfil',
        element: <PERFIL.MI_PERFIL/>,
        exact: true,
    },

    {
        path: '/mi-perfil/editar',
        element: <PERFIL.EDITAR_PERFIL/>,
        exact: true,
    },

    /**
     * Auth Page
     */
    {
        path: demoPages.page404.path,
        element: <AUTH.PAGE_404/>,
        exact: true,
    },
    {
        path: demoPages.login.path,
        element: <Login/>,
        exact: true,
    },
    {
        path: demoPages.signUp.path,
        element: <Login isSignUp/>,
        exact: true,
    },
];

const contents = [...presentation];

export default contents;
