import {getTheme} from "../../helpers";

const Avatar = ({
                    inicial,
    size = 100,
    sizeText = 40,
    pointer=false,
                }) => {

    const theme = getTheme();
    return (
        <div
            style={{
                background: theme.primario,
                width: size,
                height: size,
                borderRadius: '50%',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                cursor: pointer ? 'pointer' : 'default'
            }
            }
        >
            <span style={{
                color: 'white',
                fontSize: sizeText,
                fontWeight: '600'
            }}>{inicial}</span>
        </div>
    )
}

export default Avatar
