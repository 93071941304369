import React, {useContext} from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import Content from '../Content/Content';
import WrapperOverlay from './WrapperOverlay';
import ThemeContext from '../../contexts/themeContext';
import Header, {HeaderLeft, HeaderRight} from "../Header/Header";
import Dropdown, {DropdownItem, DropdownMenu, DropdownToggle} from "../../components/bootstrap/Dropdown";
import Button from "../../components/bootstrap/Button";
import {_getAuthHeaders, _headers, _loader, _notify, _removeLoader, _user} from "../../helpers";
import axios from "axios";
import {DOMAIN, MENSAJE_ERROR} from "../../config";
import Avatar from "../../components/Avatar/Avatar";

export const WrapperContainer = ({children, className, ...props}) => {
    const {rightPanel} = useContext(ThemeContext);
    return (
        <div
            className={classNames(
                'wrapper',
                {'wrapper-right-panel-active': rightPanel},
                className,
            )}
            // eslint-disable-next-line react/jsx-props-no-spreading
            {...props}>
            {children}
        </div>
    );
};
WrapperContainer.propTypes = {
    children: PropTypes.node.isRequired,
    className: PropTypes.string,
};
WrapperContainer.defaultProps = {
    className: null,
};

const Wrapper = () => {

    const pathname = window.location.pathname;
    const section = pathname.split("/")[1];

    console.log(section)
    let imagen = '';
    let inicial = '';
    if (section != 'login') {
        imagen = _user('imagen')
        inicial = _user('inicial');
    }


    const cerrarSesion = () => {

        _loader()
        axios.post(DOMAIN + '/auth/logout', {}, _getAuthHeaders()).then(response => {
            console.log(response)
            response = response.data
            if (!response.error) {
                _notify(response.mensaje, 'success')
                localStorage.clear()
                location.href = '/login'
            } else {
                _notify(response.mensaje, 'danger')
            }
            _removeLoader()
        }).catch(error => {
            _removeLoader()
            _notify(MENSAJE_ERROR, 'danger')
        });
    }

    const goToPerfil = () => {
        location.href = '/mi-perfil'
    }

    return (
        <>
            <WrapperContainer>
                {section !== 'login' &&
                <Header>
                    <HeaderLeft></HeaderLeft>
                    <HeaderRight>
                        <Dropdown>
                            <DropdownToggle hasIcon={false}>
                                <div style={{display: 'flex', alignItems: 'center'}}>
                                    <div className="mr-15" style={{
                                        display: 'flex',
                                        alignItems: 'center',
                                        flexDirection: 'column',
                                        justifyContent: 'flex-end'
                                    }}>
                                        <p className="m-0 negrita color-negro f15">{_user('nombre')}</p>
                                        <p className="m-0 negrita color-negro f12">{_user('rol')}</p>
                                    </div>
                                    {imagen ? (
                                        <img
                                            style={{
                                                width: 50,
                                                height: 50,
                                                borderRadius: '50%'
                                            }}
                                            src={imagen}/>
                                    ) : (
                                        <Avatar pointer={true} size={50} sizeText={20} inicial={inicial}/>
                                    )}
                                    {/*<img style={{width: '50px', height: '50px', borderRadius: '50%'}} src={avatar}/>*/}
                                </div>
                            </DropdownToggle>
                            <DropdownMenu isAlignmentEnd>

                                <DropdownItem>
                                    <Button icon='User' onClick={() => goToPerfil()}>
                                        Mi perfil
                                    </Button>
                                </DropdownItem>

                                <DropdownItem isDivider/>

                                <DropdownItem>
                                    <Button icon='Editj' onClick={() => cerrarSesion()}>
                                        Cerrar sesión
                                    </Button>
                                </DropdownItem>

                            </DropdownMenu>
                        </Dropdown>
                    </HeaderRight>
                </Header>
                }

                <Content/>
            </WrapperContainer>
            <WrapperOverlay/>
        </>
    );
};

export default Wrapper;
