import React, {
    useContext, useRef, useState,
} from 'react';
import classNames from 'classnames';
import {motion} from 'framer-motion';
import Brand from '../Brand/Brand';
import Navigation, {NavigationLine} from '../Navigation/Navigation';
import {
    menuPacientes,
    menuPacientesAdmin,
    menuCalendario,
    menuCalendarioAdmin,
    menuAnalisisEInformes,
    menuConfiguracion,
    menuCentrosMedicos,
    menuPersonalMedico

} from '../../menu';
import ThemeContext from '../../contexts/themeContext';
import Tooltips from '../../components/bootstrap/Tooltips';
import useAsideTouch from '../../hooks/useAsideTouch';
import Icon from "../../components/icon/Icon";
import {Link} from 'react-router-dom';
import {_user, getTheme} from "../../helpers";

const Aside = () => {
    const {asideStatus, setAsideStatus} = useContext(ThemeContext);
    let {asideStyle, touchStatus, hasTouchButton, asideWidthWithSpace, x} = useAsideTouch();
    const dataTheme = getTheme();
    asideStyle.backgroundColor = dataTheme.aside
    console.log(asideStyle)

    const isModernDesign = process.env.REACT_APP_MODERN_DESGIN === 'true';

    const constraintsRef = useRef(null);
    return (
        <>
            <motion.aside
                style={asideStyle}
                className={classNames(
                    'aside',
                    {open: asideStatus},
                    {
                        'aside-touch-bar': hasTouchButton && isModernDesign,
                        'aside-touch-bar-close': !touchStatus && hasTouchButton && isModernDesign,
                        'aside-touch-bar-open': touchStatus && hasTouchButton && isModernDesign,
                    },
                )}>
                <div className='aside-head pt-3'>
                    <Brand asideStatus={asideStatus} setAsideStatus={setAsideStatus}/>
                </div>
                <div className='aside-body'>

                    <div style={{width: '100%', display: 'flex', alignItems: 'center', justifyContent: 'center'}}>
                        {(  _user('rol') == 'Administrador' ||
                            _user('rol') == 'Contacto Administrativo' ||
                            _user('rol') == 'Contacto Técnico' ||
                            _user('rol') == 'Médico Cirujano' ) && (
                            <Link to="/calendario/nueva-cita"
                                  className="btn-crear-reporte"
                                  style={{
                                      width: asideStatus ? '88%' : '50px',
                                      textDecoration: 'none',
                                      color: 'white',
                                      backgroundColor: dataTheme.primario

                                  }}>

                                {asideStatus && <span>Agendar Cirugía</span>}
                                <Icon icon='Plus' size='2x'/>
                            </Link>
                        )}
                    </div>

                    {_user('rol') !== 'Super Administrador' && (
                    <>
                        <NavigationLine/>
                        <Navigation
                            menu={(_user('rol') === 'Médico Especialista' ? menuCalendario : menuCalendarioAdmin)}
                            id='menu-calendario'/>
                    </>
                    )}

                    {_user('rol') === 'Super Administrador' && (
                        <>
                            <NavigationLine/>
                            <Navigation menu={menuCentrosMedicos} id='centros-medicos'/>
                            <NavigationLine/>
                            <Navigation menu={menuAnalisisEInformes} id='menu-analisis-e-informes'/>
                        </>
                    )}


                    {(_user('rol') !== 'Super Administrador') && (

                        <>
                        {(  _user('rol') === 'Super Administrador' ||
                            _user('rol') === 'Contacto Administrativo' ||
                            _user('rol') === 'Contacto Técnico') && (
                                <>
                                    <NavigationLine/>
                                    <Navigation menu={menuAnalisisEInformes} id='menu-analisis-e-informes'/>

                                    <NavigationLine/>
                                    <Navigation menu={menuPersonalMedico} id='personal-medico'/>
                                </>
                            )}


                            {_user('hospital')?.origin === 'Externo' &&
                            <>
                                <NavigationLine/>
                                <Navigation menu={menuConfiguracion} id='configuracion'/>
                            </>
                            }

                            {(
                                _user('rol') === 'Médico Cirujano' ||
                                _user('rol') === 'Administrador'   ||
                                _user('rol') === 'Contacto Técnico'||
                                _user('rol') === 'Contacto Administrativo'
                            ) &&
                            (
                                <>
                                    <NavigationLine/>
                                    <Navigation menu={menuPacientesAdmin} id='menu-pacientes'/>
                                </>
                            )}
                        </>
                    )}


                    <br/>

                </div>
            </motion.aside>
            {asideStatus && hasTouchButton && isModernDesign && (
                <>
                    <motion.div className='aside-drag-area' ref={constraintsRef}/>
                    <Tooltips title='Toggle Aside' flip={['top', 'right']}>
                        <motion.div
                            className='aside-touch'
                            drag='x'
                            whileDrag={{scale: 1.2}}
                            whileHover={{scale: 1.1}}
                            dragConstraints={constraintsRef}
                            // onDrag={(event, info) => console.log(info.point.x, info.point.y)}
                            dragElastic={0.1}
                            style={{x, zIndex: 1039}}
                            onClick={() => x.set(x.get() === 0 ? asideWidthWithSpace : 0)}
                        />
                    </Tooltips>
                </>
            )}
        </>
    );
};

export default Aside;
