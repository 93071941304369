import React, {useState, useRef, useEffect} from 'react';
import {Link, useSearchParams} from 'react-router-dom';
import classNames from 'classnames';
import PageWrapper from '../../layout/PageWrapper/PageWrapper';
import Page from '../../layout/Page/Page';
import Card, {CardBody} from '../../components/bootstrap/Card';
import FormGroup from '../../components/bootstrap/forms/FormGroup';
import Input from '../../components/bootstrap/forms/Input';
import Button from '../../components/bootstrap/Button';
import useDarkMode from '../../hooks/useDarkMode';

import logoColor from '../../assets/logos/logo_2023_color.png';
import logoMicrosoft from '../../assets/logos/logo-microsoft.png';
import {_undefined, _notify, _headers, _loader, _removeLoader, _getParam} from '../../helpers';
import axios from 'axios';
import {DOMAIN, MENSAJE_ERROR, URL_LOGIN_AZURE} from '../../config';
import Icon from '../../components/icon/Icon';

const LoginHeader = () => {
    return (
        <>
            <div className='text-center h1 fw-bold mt-5 color-charcoal'>Bienvenido</div>
            <div style={{color: 'white'}} className='text-center h4 mb-5 color-charcoal'>
                Inicia sesión para continuar
            </div>
        </>
    );
};

const Index = () => {
    const [searchParams] = useSearchParams();
    const {darkModeStatus} = useDarkMode();
    const [validUsernameInput, setValidUsernameInput] = useState(false);
    const [usernameInput, setUsernameInput] = useState('');
    const [passwordInput, setPasswordInput] = useState('');
    const [conPasswordInput, setConPasswordInput] = useState('');
    const refPassword = useRef(null);
    const refConPassword = useRef(null);

    const [isRecoveryPassword, setIsRecoveryPassword] = useState(false);
    const [isNewPassword, setIsNewPassword] = useState(false);
    const [tokenNewPassword, setTokenNewPassword] = useState('');

    useEffect(() => {
        const dataParam = _getParam('data');
        if (searchParams.get('resetpassword') && searchParams.get('token')) {
            console.log('token reovery pass');
            let error = searchParams.get('error');
            if (error == 'true') {
                _notify('El token ha caducado.', 'danger');
                setTimeout(() => {
                    location.href = '/login';
                }, 2000);
            }
            setIsNewPassword(true);
            setTokenNewPassword(searchParams.get('token'));
						setUsernameInput(searchParams.get('email'));
        }
        if (!_undefined(dataParam)) {
            let decode = atob(dataParam);
            let data = JSON.parse(decode);

            // debugger
            if (!data.error) {
                _notify(data.message, 'success');

                localStorage.setItem('_myToken', data.access_token);
                localStorage.setItem('_dataUser', JSON.stringify(data.data));
                location.href = '/';
                return;
            } else {
							return _notify(data.message, 'danger');
            }
        }
    }, []);

    const onKeyDownPassword = (evt) => {
        if (evt.key === 'Enter') {
            evt.preventDefault();
            handleLogin();
        }
    };

    const onKeyDownUsername = (evt) => {
        if (evt.key === 'Enter') {
            evt.preventDefault();
            if (_undefined(usernameInput)) {
                return _notify('Introduce tu usuario.', 'danger');
            }
            setValidUsernameInput(true);
        }
    };

    const handleRecoveryPassword = () => {
        setIsRecoveryPassword(true);
    };
    const handleEnviarLinkContrasena = () => {
        console.log('Enviar link', searchParams);
        if (_undefined(usernameInput)) {
            return _notify('Introduce tu usuario.', 'danger');
        }

        let data = {email: usernameInput};

        _loader();
        axios
            .post(DOMAIN + '/user/restore_pass', data, _headers())
            .then((response) => {
                console.log(response);
                response = response.data;
                if (!response.error) {
                    _notify(response.message, 'success');
                    setTimeout(() => {
                        location.href = '/login';
                    }, 3000);
                    /*localStorage.setItem('_myToken',response.access_token)
                localStorage.setItem('_dataUser', JSON.stringify(response.data));
                if(response.data.rol == 'Médico Especialista'){
                    location.href='/calendario/citas-agendadas'
                }else{
                    location.href='/'
                }*/
                } else {
                    _notify(response.message, 'danger');
                }
                _removeLoader();
            })
            .catch((error) => {
                console.log(error.response);
                _removeLoader();
                _notify(error.response.data.message, 'danger');
            });
    };

    const cambiarUsuario = () => {
        if (isRecoveryPassword) {
            setIsRecoveryPassword(false);
            setValidUsernameInput(false);
        } else {
            setValidUsernameInput(false);
        }
    };

    const handleOnChangeUsername = (evt) => {
        let value = evt.target.value;
        setUsernameInput(value);
    };

    const handleOnChangePassword = (evt) => {
        let value = evt.target.value;
        setPasswordInput(value);
    };

    const handleOnChangeConPassword = (evt) => {
        let value = evt.target.value;
        setConPasswordInput(value);
    };

    const handleContinue = () => {
        if (isNewPassword) {
            if (_undefined(passwordInput)) {
                return _notify('Introduce tu nueva contraseńa.', 'danger');
            }
            if (_undefined(conPasswordInput)) {
                return _notify('Introduce la confirmación de tu nueva contraseńa.', 'danger');
            }
            if (passwordInput !== conPasswordInput) {
                return _notify('Las contraseñas no coinciden.', 'danger');
            }

            let data = {
                password_confirmation: conPasswordInput,
                password: passwordInput,
                email: usernameInput,
                token: tokenNewPassword,
            };

            _loader();
            axios
                .post(DOMAIN + '/user/setpassword', data, _headers())
                .then((response) => {
                    console.log(response);
                    response = response.data;
                    if (!response.error) {
                        _notify(response.message, 'success');
                        setTimeout(() => {
                            location.href = '/login';
                        }, 2000);
                        /*
                    localStorage.setItem('_myToken',response.access_token)
                    localStorage.setItem('_dataUser', JSON.stringify(response.data));
                    if(response.data.rol == 'Médico Especialista'){
                        location.href='/calendario/citas-agendadas'
                    }else{
                        location.href='/'
                    }*/
                    } else {
                        _notify(response.message, 'danger');
                    }
                    _removeLoader();
                })
                .catch((error) => {
                    _removeLoader();
                    _notify(error.response.data.message, 'danger');
                });

            return false;
        }
        if (_undefined(usernameInput)) {
            return _notify('Introduce tu usuario.', 'danger');
        }
        setValidUsernameInput(true);
    };

    const handleLogin = () => {
        if (_undefined(usernameInput)) {
            return _notify('Introduce tu usuario.', 'danger');
        }
        if (_undefined(passwordInput)) {
            return _notify('Introduce tu contraseña.', 'danger');
        }

        let data = {email: usernameInput, password: passwordInput};

        _loader();
        axios
            .post(DOMAIN + '/auth/login', data, _headers())
            .then((response) => {
                console.log(response);
                response = response.data;
                if (!response.error) {
                    _notify(response.mensaje, 'success');

                    localStorage.setItem('_myToken', response.access_token);
                    localStorage.setItem('_dataUser', JSON.stringify(response.data));

                    if (response.data.rol == 'Super Administrador') {
						location.href = '/';
                    } else {
                        if (response.data.rol == 'Médico Especialista') {
                            location.href = '/calendario/citas-agendadas';
                        } else {
                            location.href = '/';
                        }
                    }

                } else {
                    _notify(response.mensaje, 'danger');
                }
                _removeLoader();
            })
            .catch((error) => {
                _removeLoader();
                _notify(error.response.data.message, 'danger');
            });
    };

    return (
        <PageWrapper title='Login' className='bg-login'>
            <Page className='p-0'>
                <div className='row h-100 align-items-center justify-content-center'>
                    <div className='col-xl-4 col-lg-6 col-md-8 shadow-3d-container'>
                        <Card className='shadow-3d-darkn' data-tour='login-page'>
                            <CardBody>
                                <div className='text-center my-5'>
                                    <Link
                                        to='/login'
                                        className={classNames(
                                            'text-decoration-none  fw-bold display-2',
                                            {
                                                'text-dark': !darkModeStatus,
                                                'text-light': darkModeStatus,
                                            },
                                        )}>
                                        <img
                                            src={logoColor}
                                            alt='Medilens'
                                            style={{width: '180px', color: '#fff'}}
                                        />
                                    </Link>
                                </div>

                                <LoginHeader/>

                                <br/>
                                {/**
                                 <div style={{
									width:"100%",
									display:"flex",
									alignItems:"center",
									justifyContent:"center"
								}}>
                                 <a
                                 href={URL_LOGIN_AZURE}
                                 // target="_blank"
                                 className={"btn-login-microsoft"}
                                 style={{
											width:"240px",
											height:"55px",
											display:"flex",
											alignItems:"center",
											justifyContent:"center",
											textDecoration:'none',
											color:"white",
											backgroundColor: 'rgb(23,62,130)'
										}}
                                 >
                                 <img src={logoMicrosoft}
                                 style={{width:"45px"}}
                                 ></img>
                                 <span style={{
											fontSize:"16px"
										}}>Sign in with Microsoft</span>
                                 </a>
                                 </div>*/}

                                <form className='row g-4 form-login pd20'>
                                    <div className='col-12 '>
                                        {!validUsernameInput ? (
                                            <>
                                                {isNewPassword ? (
                                                    <>
                                                        <FormGroup
                                                            id='login-password'
                                                            isFloating
                                                            label='Ingresa tu nueva contraseña'>
                                                            <Input
                                                                autoFocus
                                                                ref={refPassword}
                                                                onKeyDown={(evt) =>
                                                                    onKeyDownPassword(evt)
                                                                }
                                                                type='password'
                                                                className='in-login color-charcoal'
                                                                value={passwordInput}
                                                                onChange={(evt) => {
                                                                    handleOnChangePassword(evt);
                                                                }}
                                                            />
                                                        </FormGroup>
                                                        <FormGroup
                                                            id='login-con-password'
                                                            isFloating
                                                            label='Confirma tu nueva contraseña'>
                                                            <Input
                                                                autoFocus
                                                                ref={refConPassword}
                                                                onKeyDown={(evt) =>
                                                                    onKeyDownPassword(evt)
                                                                }
                                                                type='password'
                                                                className='in-login color-charcoal'
                                                                value={conPasswordInput}
                                                                onChange={(evt) => {
                                                                    handleOnChangeConPassword(evt);
                                                                }}
                                                            />
                                                        </FormGroup>
                                                    </>
                                                ) : (
                                                    <FormGroup
                                                        id='login-username'
                                                        isFloating
                                                        label='Ingresa tu usuario'>
                                                        <Input
                                                            autoFocus
                                                            onKeyDown={(evt) =>
                                                                onKeyDownUsername(evt)
                                                            }
                                                            className='in-login color-charcoal'
                                                            value={usernameInput}
                                                            onChange={(evt) => {
                                                                handleOnChangeUsername(evt);
                                                            }}
                                                        />
                                                    </FormGroup>
                                                )}
                                            </>
                                        ) : (
                                            <div>
                                                <div
                                                    onClick={() => cambiarUsuario()}
                                                    style={{
                                                        display: 'flex',
                                                        alignItems: 'center',
                                                        padding: '10px 0px',
                                                        cursor: 'pointer',
                                                    }}>
                                                    <Icon
                                                        className='f35 color-charcoal'
                                                        icon='ArrowBack'
                                                    />
                                                    <p className='m0 ml-5 color-charcoal'>
                                                        {isRecoveryPassword
                                                            ? 'Regresar'
                                                            : 'Cambiar usuario'}
                                                    </p>
                                                </div>
                                                {isRecoveryPassword && (
                                                    <FormGroup
                                                        id='recovery-password'
                                                        isFloating
                                                        label='Ingresa tu usuario'>
                                                        <Input
                                                            autoFocus
                                                            ///ref={refPassword}
                                                            onKeyDown={(evt) =>
                                                                onKeyDownUsername(evt)
                                                            }
                                                            type='email'
                                                            className='in-login color-charcoal'
                                                            value={usernameInput}
                                                            onChange={(evt) => {
                                                                handleOnChangeUsername(evt);
                                                            }}
                                                        />
                                                    </FormGroup>
                                                )}

                                                {!isRecoveryPassword && !isNewPassword && (
                                                    <FormGroup
                                                        id='login-password'
                                                        isFloating
                                                        label='Ingresa tu contraseña'>
                                                        <Input
                                                            autoFocus
                                                            ref={refPassword}
                                                            onKeyDown={(evt) =>
                                                                onKeyDownPassword(evt)
                                                            }
                                                            type='password'
                                                            className='in-login color-charcoal'
                                                            value={passwordInput}
                                                            onChange={(evt) => {
                                                                handleOnChangePassword(evt);
                                                            }}
                                                        />
                                                    </FormGroup>
                                                )}
                                            </div>
                                        )}
                                    </div>
                                    <div className='col-12'>
                                        {!validUsernameInput ? (
                                            <Button
                                                className='w-100 py-3 button-login'
                                                onClick={() => handleContinue()}>
                                                Continuar
                                            </Button>
                                        ) : (
                                            <>
                                                {!isRecoveryPassword && !isNewPassword && (
                                                    <>
                                                        <Button
                                                            className='w-100 py-3 button-login mb-4'
                                                            onClick={() => handleLogin()}>
                                                            Iniciar sesión
                                                        </Button>
                                                        <Button
                                                            className='w-100 py-1 button-recovery'
                                                            onClick={() =>
                                                                handleRecoveryPassword()
                                                            }>
                                                            ¿Ha olvidado su contraseña?
                                                        </Button>
                                                    </>
                                                )}

                                                {isRecoveryPassword && (
                                                    <Button
                                                        className='w-100 py-3 button-login mb-4'
                                                        onClick={() =>
                                                            handleEnviarLinkContrasena()
                                                        }>
                                                        Recuperar contraseña
                                                    </Button>
                                                )}
                                            </>
                                        )}
                                    </div>
                                </form>
                                <br/>
                                <br/>
                                <br/>
                            </CardBody>
                        </Card>
                    </div>
                </div>
            </Page>
        </PageWrapper>
    );
};

export default Index;
